import { Box, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import LaborTab from './components/labor_tab/components/LaborTab';
import PopulationContractTab from './components/contract_tab/components/PopulationContractTab';
import { getStudyPeriod } from '../../common/constants/study_period';

const PopulationPage = ({ studyPeriod, entityId, studyType }) => {
  const content = (
    <div>
      <Header variant="h1">Population</Header>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'Labor',
              id: 'first',
              content: <LaborTab />,
            },
            {
              label: 'Contract',
              id: 'second',
              content: <PopulationContractTab />,
            },
          ]}
        />
      </Box>
    </div>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_POPULATION}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.POPULATION(studyPeriod, entityId, studyType)}
    />
  );
};

PopulationPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
});

export default connect(mapStateToProps)(PopulationPage);
