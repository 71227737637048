import { Button, Container, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { forEach, get } from 'lodash';
import { RESOURCE } from '../../auth/constants/rbac_constants';
import { BRC_ITEMS_PAGE } from '../../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../../common/components/with_page/Page';
import { getStudyPeriod } from '../../../common/constants/study_period';
import './css/style.css';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../common/constants/route_paths';
import { configureSurveysAction } from './redux/surveyConfiguration';
import { getConfigureSurveyPayload } from './utils/SurveyUtils';
import getSurveyPageRenderingConfig from './rendering_config/SurveyPageRenderingConfig';

const CreateSurveyPage = ({
  studyPeriod,
  pageHeaderName,
  navigateTo,
  configureSurveys,
  entityId,
  studyType,
  surveyType,
}) => {
  const initialState = {
    LaborSec41: { releaseDate: '', dueDate: '', template: null },
    LaborSec41Expanded: { releaseDate: '', dueDate: '', template: null },
    Contract: { releaseDate: '', dueDate: '', template: null },
    LaborDual: { releaseDate: '', dueDate: '', template: null },
  };
  const [state, setState] = useState(initialState);

  const renderingConfig = useMemo(() => {
    return getSurveyPageRenderingConfig(
      process.env.REACT_APP_CLIENT_ID,
      surveyType,
      'CreateSurveyPage',
    );
  }, [surveyType]);

  const navigateToSurveyPage = () => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriod),
    });
  };
  const onConfirmCreateSurvey = () => {
    forEach(renderingConfig.surveyTypesToConfirm, surveyTypeIteratee => {
      configureSurveys({
        body: getConfigureSurveyPayload(state, surveyTypeIteratee),
        onSuccess: navigateToSurveyPage,
      });
    });
  };

  const content = (
    <SpaceBetween size="l">
      <Header variant="h1">{pageHeaderName}</Header>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={navigateToSurveyPage}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onConfirmCreateSurvey}
              disabled={renderingConfig.isConfirmButtonDisabled(state)}
            >
              Confirm
            </Button>
          </SpaceBetween>
        }
        className="create-survey-form"
      >
        <Container header={<Header>Default</Header>}>
          {renderingConfig.renderCreateSurveyComponents({
            state,
            setState,
          })}
        </Container>
      </Form>
    </SpaceBetween>
  );
  return (
    <Page
      PageId={RESOURCE.PAGE_SURVEY}
      isPageAccessControlled={false}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.CREATE_SURVEY(studyPeriod, entityId, studyType)}
    />
  );
};

CreateSurveyPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  pageHeaderName: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  configureSurveys: PropTypes.func.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
  surveyType: PropTypes.string,
};

CreateSurveyPage.defaultProps = {
  pageHeaderName: 'Create survey(s)',
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
  surveyType: get(state, 'studyType.surveyType'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigateTo: payload => push(payload),
      configureSurveys: configureSurveysAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurveyPage);
