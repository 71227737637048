import { Box, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import { getStudyPeriod } from '../../common/constants/study_period';
import BusinessComponentsTab from './components/business_component_tab/BusinessComponentTab';
import ProjectMappingTab from './components/project_mapping_tab/ProjectMappingTab';

const BusinessComponentPage = ({ studyPeriod, entityId, studyType }) => {
  const content = (
    <div>
      <Header variant="h1">Business components</Header>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'Business components',
              id: 'first',
              content: <BusinessComponentsTab />,
            },
            {
              label: 'Projects',
              id: 'second',
              content: <ProjectMappingTab />,
            },
          ]}
        />
      </Box>
    </div>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_BUSINESS_COMPONENT}
      isPageAccessControlled
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.BUSINESS_COMPONENT(studyPeriod, entityId, studyType)}
    />
  );
};

BusinessComponentPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
});

export default connect(mapStateToProps)(BusinessComponentPage);
