import * as React from 'react';
import { get } from 'lodash';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { exportDataAction } from '../../../../../../common/redux/export';
import { getStudyPeriod } from '../../../../../../common/constants/study_period';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
} from '../../../../../../common/config/api_constants';

const ProjectMappingActions = ({ exportDataBegin, query, studyPeriod, entityId }) => {
  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'BC_PROJECT_MAPPING_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          country: DEFAULT_COUNTRY,
          businessId: DEFAULT_BUSINESS_ID + (entityId === undefined ? '' : entityId),
          query,
        },
      },
    });
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={onExportHandler}>Export</Button>
    </SpaceBetween>
  );
};

ProjectMappingActions.propTypes = {
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    studyPeriod: getStudyPeriod(state),
    entityId: get(state, 'entityId.entityId'),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportDataBegin: exportDataAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMappingActions);
