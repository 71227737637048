import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../common/components/table/constants';

import SurveyActions from '../components/SurveyActions';
import { getImportStatusHeaderDescription } from '../../import_data/utils/import_utils';
import { SUPPORTED_IMPORT_TYPES } from '../../import_data/constants/import_types';
import { SURVEY_TYPE } from '../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';

const EmptyTableView = () => <p>No surveys.</p>;

export default ({ importTypeMetadata, surveyType }) => {
  return {
    dataTrackingId: 'surveyId',
    dataLoadingText: 'Loading surveys',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Surveys',
      description: getImportStatusHeaderDescription(importTypeMetadata),
      Actions: SurveyActions,
      importTypeMetadata,
    },
    // TODO : This needs to be dynamic
    getDataRequestBody: () => {
      return { surveyType };
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes:
            SURVEY_TYPE.LABOR_DUAL === surveyType
              ? SUPPORTED_IMPORT_TYPES.LABOR_DUAL_SURVEY
              : SUPPORTED_IMPORT_TYPES.SURVEY,
        },
      };
    },
  };
};

export const checkSurveyRequest = {
  body: {
    pageSize: 1,
    pageNumber: 1,
    query: '',
  },
};
