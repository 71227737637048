import * as React from 'react';
import { isEmpty, isNull } from 'lodash';
import { SpaceBetween } from '@amzn/awsui-components-react';
import CreateSurveysComponent from '../CreateSurveyComponent';
import { SURVEY_TYPE } from '../../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';

/*
Survey rendering configuration based on different attributes.
This eliminates different type of conditional rendering on
different combination of attributes and keeps component clean,
configurable and extensible when we will have new types of jurisdiction
and survey type etc
This single config object can be used by different components
which are not directly used as dependency in below config pages
to avoid import/no-cycle
*/
const surveyRenderingConfig = {
  Default: {
    CreateSurveyPage: {
      isConfirmButtonDisabled: state => {
        return (
          isEmpty(state.LaborSec41.releaseDate) ||
          isEmpty(state.LaborSec41.dueDate) ||
          isNull(state.LaborSec41.template) ||
          isEmpty(state.LaborSec41Expanded.releaseDate) ||
          isEmpty(state.LaborSec41Expanded.dueDate) ||
          isNull(state.LaborSec41Expanded.template) ||
          isEmpty(state.Contract.releaseDate) ||
          isEmpty(state.Contract.dueDate) ||
          isNull(state.Contract.template)
        );
      },
      renderCreateSurveyComponents: ({ state, setState }) => (
        <SpaceBetween size="xl" className="create-survey-container">
          <CreateSurveysComponent
            surveyType={SURVEY_TYPE.LABOR}
            state={state}
            setState={setState}
            populationName=" - Sec. 41"
            populationType="LaborSec41"
          />
          <CreateSurveysComponent
            surveyType={SURVEY_TYPE.LABOR}
            state={state}
            setState={setState}
            populationName=" - Sec. 41 Expanded"
            populationType="LaborSec41Expanded"
          />
          <CreateSurveysComponent
            surveyType={SURVEY_TYPE.CONTRACT}
            state={state}
            setState={setState}
            populationType="Contract"
          />
        </SpaceBetween>
      ),
      surveyTypesToConfirm: [SURVEY_TYPE.LABOR, SURVEY_TYPE.CONTRACT],
    },
  },
  Radical: {
    [SURVEY_TYPE.LABOR_DUAL]: {
      CreateSurveyPage: {
        isConfirmButtonDisabled: state => {
          return (
            isEmpty(state.LaborDual.releaseDate) ||
            isEmpty(state.LaborDual.dueDate) ||
            isNull(state.LaborDual.template)
          );
        },
        renderCreateSurveyComponents: ({ state, setState }) => (
          <SpaceBetween size="xl" className="create-survey-container">
            <CreateSurveysComponent
              surveyType={SURVEY_TYPE.LABOR_DUAL}
              state={state}
              setState={setState}
              populationType={SURVEY_TYPE.LABOR_DUAL}
            />
          </SpaceBetween>
        ),
        surveyTypesToConfirm: [SURVEY_TYPE.LABOR_DUAL],
      },
    },
  },
};

// Traverse through the config using optional chaining, with fallback to defaults
const getSurveyPageRenderingConfig = (clientId, surveyType, page) => {
  const config =
    surveyRenderingConfig?.[clientId]?.[surveyType]?.[page] ||
    surveyRenderingConfig?.Default?.[page]; // Global default config
  return config || {};
};

export default getSurveyPageRenderingConfig;
