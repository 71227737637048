import { Component } from 'react';
import PropTypes from 'prop-types';

class StudyPeriodComponent extends Component {
  componentDidUpdate(prevProps) {
    const { studyPeriod, entityId, studyType } = this.props;
    if (
      studyPeriod !== prevProps.studyPeriod ||
      (entityId !== undefined && entityId !== prevProps.entityId) ||
      studyType !== prevProps.studyType
    ) {
      // Clear data for existing studyPeriod
      this.clearData();
      // Fetch data for updated studyPeriod
      this.fetchData();
    }
  }

  // eslint-disable-next-line
  fetchData() {
    throw new Error("Method 'fetchData()' must be implemented.");
  }

  // eslint-disable-next-line
  clearData() {
    throw new Error("Method 'clearData()' must be implemented.");
  }
}

StudyPeriodComponent.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

export default StudyPeriodComponent;
