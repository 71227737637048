import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducers';
import entityIdReducer from './entityIdReducers';
import entities from './entities';
import studyTypeReducer from './studyTypeReducers';
import appMetaData from '../../common/components/with_page/redux/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    entityId: entityIdReducer,
    studyType: studyTypeReducer,
    appMetaData,
    entities,
  });
