import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import ProjectMappingTable from './components/project_mapping_table/ProjectMappingTable';

const ProjectMappingTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
      <div>
        <ProjectMappingTable />
      </div>
    </Grid>
  );
};

export default ProjectMappingTab;
