import { get, map } from 'lodash';
import { SURVEY_TYPE } from '../../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';
import { convertTimeZone } from '../../../../common/utils/date_time_util';
import { DEFAULT_TIMEZONE } from '../../../../common/config/api_constants';

export const getTemplates = (data, surveyType) => {
  let content;

  switch (surveyType) {
    case 'Contract': {
      content = get(data, 'updateContractSurveyTemplateNames', []);
      break;
    }
    case 'Labor': {
      content = get(data, 'updateLaborSurveyTemplateNames', []);
      break;
    }
    case 'LaborDual': {
      content = get(data, 'updateLaborDualAllocationSurveyTemplateNames', []);
      break;
    }
    default:
      content = [];
  }
  return map(content, template => {
    return { label: template, value: template };
  });
};

export const getConfigureSurveyPayload = (state, surveyType) => {
  switch (surveyType) {
    case SURVEY_TYPE.CONTRACT:
      return {
        templateDocumentId: {
          defaultValue: get(state, `Contract.template.value`),
        },
        releaseDate: {
          defaultValue: Date.parse(
            convertTimeZone(
              `${get(state, `Contract.releaseDate`).toString()}T00:00:00`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
        },
        dueDate: {
          defaultValue: Date.parse(
            convertTimeZone(
              `${get(state, `Contract.dueDate`).toString()}T23:59:59`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
        },
        surveyType,
      };
    case SURVEY_TYPE.LABOR_DUAL:
      return {
        templateDocumentId: {
          defaultValue: get(state, `LaborDual.template.value`),
        },
        releaseDate: {
          defaultValue: Date.parse(
            convertTimeZone(
              `${get(state, `LaborDual.releaseDate`).toString()}T00:00:00`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
        },
        dueDate: {
          defaultValue: Date.parse(
            convertTimeZone(
              `${get(state, `LaborDual.dueDate`).toString()}T23:59:59`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
        },
        surveyType,
      };
    default:
    case SURVEY_TYPE.LABOR:
      return {
        templateDocumentId: {
          section41: get(state, `LaborSec41.template.value`),
          section41Expanded: get(state, `LaborSec41Expanded.template.value`),
          defaultValue: get(state, `LaborSec41.template.value`),
        },
        releaseDate: {
          section41: Date.parse(
            convertTimeZone(
              `${get(state, `LaborSec41.releaseDate`).toString()}T00:00:00`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
          section41Expanded: Date.parse(
            convertTimeZone(
              `${get(state, `LaborSec41Expanded.releaseDate`).toString()}T00:00:00`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
          defaultValue: Date.parse(
            convertTimeZone(
              `${get(state, `LaborSec41.releaseDate`).toString()}T00:00:00`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
        },
        dueDate: {
          section41: Date.parse(
            convertTimeZone(
              `${get(state, `LaborSec41.dueDate`).toString()}T23:59:59`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
          section41Expanded: Date.parse(
            convertTimeZone(
              `${get(state, `LaborSec41Expanded.dueDate`).toString()}T23:59:59`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
          defaultValue: Date.parse(
            convertTimeZone(
              `${get(state, `LaborSec41.dueDate`).toString()}T23:59:59`,
              DEFAULT_TIMEZONE,
              'UTC',
            ).toString(),
          ).toString(),
        },
        surveyType,
      };
  }
};
