import { combineEpics } from 'redux-observable';
import { searchContractGroupingEpic } from '../../app/grouping/components/contract_tab/components/contract_table/redux/contractGrouping';
import { searchLaborGroupingEpic } from '../../app/grouping/components/labor_tab/components/labor_table/redux/laborGrouping';
import { laborEpics } from '../../app/population/components/labor_tab/redux';
import { searchCostCentersQNQEpic } from '../../app/qnq/components/cost_centers_tab/components/costs_table/redux/costCentersQnq';
import { updateCostCentersQNQEpic } from '../../app/qnq/components/cost_centers_tab/components/costs_table/redux/updateCostCentersQnq';
import { searchJobCodesQNQEpic } from '../../app/qnq/components/job_codes_tab/components/job_codes_table/redux/jobCodesQnq';
import { updateJobCodesQNQEpic } from '../../app/qnq/components/job_codes_tab/components/job_codes_table/redux/updateJobCodesQnq';
import { searchSurveysEpic } from '../../app/survey/redux/surveys';
import { searchProjectsQNQEpic } from '../../app/qnq/components/projects_tab/components/projects_table/redux/projectsQnq';
import { getStudySummaryEpic } from '../../app/study_summary/redux';
import { listPermissionsEpic } from '../../common/components/with_page/redux/reducer';
import { getSchemasEpic } from '../reducers/entities/schemas';
import { getNotificationEventsOverviewEpic } from '../../app/notification/NotificationEventsOverview/redux';
import { searchNotificationsEpic } from '../../app/notification/Notifications/components/recipients_tab/components/recipients_table/redux';
import { configureSurveysEpic } from '../../app/survey/components/redux/surveyConfiguration';
import { searchContractPopulationEpic } from '../../app/population/components/contract_tab/components/vendors_table/redux';
import { updateContractGroupingEpic } from '../../app/grouping/components/contract_tab/components/contract_table/redux/updateContractGrouping';
import { updateLaborGroupingEpic } from '../../app/grouping/components/labor_tab/components/labor_table/redux/updateLaborGrouping';
import { overrideLaborGroupingEpic } from '../../app/grouping/components/labor_tab/components/labor_table/redux/overrideLaborGrouping';
import { overrideContractGroupingEpic } from '../../app/grouping/components/contract_tab/components/contract_table/redux/overrideContractGrouping';
import { updateProjectsQNQEpic } from '../../app/qnq/components/projects_tab/components/projects_table/redux/updateProjectsQnq';
import { updateSurveyEpic } from '../../app/survey/redux/UpdateSurveys';
import { searchLaborQREsEpic } from '../../app/qre/components/labor_tab/components/qre_table/redux/laborQREs';
import { updateQREsEpic } from '../../app/qre/redux/updateQREs';
import { exportDataEpic } from '../../common/redux/export';
import { checkSurveysEpic } from '../../app/survey/redux/createSurveyChecker';
import { searchReportsEpic } from '../../app/analytics/components/report_table/redux/reporting';
import { triggerReportRefreshEpic } from '../../app/analytics/components/report_table/redux/TriggerReportRefresh';
import { getReportRefreshStatusEpic } from '../../app/analytics/components/report_table/redux/GetReportRefreshStatus';
import { searchContractQREsEpic } from '../../app/qre/components/contract_tab/components/qre_table/redux/contractQREs';
import { searchSuppliesQREsEpic } from '../../app/qre/components/supplies_tab/components/qre_table/redux/suppliesQREs';
import { triggerImportEpic } from '../../app/import_data/redux/TriggerImport';
import fileImporterEpics from '../../common/components/file_importer/redux/epics';
import { configureNotificationEpic } from '../../app/notification/configure_notification/redux/configure-notification-slice';
import { getNotificationFormDataEpic } from '../../app/notification/configure_notification/redux/notification-form-data';
import { deleteNotificationEpic } from '../../app/notification/Notifications/components/Overview/redux/deleteNotifications';
import { createNotificationScheduleEpic } from '../../app/notification/Notifications/components/Overview/redux/createNotificationSchedule';
import { getNotificationDetailsEpic } from '../../app/notification/Notifications/components/Overview/redux/getNotificationDetails';
import { cancelScheduledNotificationEpic } from '../../app/notification/Notifications/components/Overview/redux/cancelScheduledNotification';
import getFileFromS3Epic from '../../common/components/get_file_from_s3/redux/epics';
import { searchSuppliesPopulationEpic } from '../../app/grouping/components/supplies_tab/components/supplies_table/redux/suppliesPopulation';
import { getImportMetadataEpic } from '../../app/import_data/redux/GetImportMetadata';
import { searchBusinessComponentEpic } from '../../app/business_component/components/business_component_tab/components/business_component_table/redux/businessComponentReducer';

export default combineEpics(
  listPermissionsEpic,
  getSchemasEpic,
  laborEpics,
  searchCostCentersQNQEpic,
  updateCostCentersQNQEpic,
  searchProjectsQNQEpic,
  searchJobCodesQNQEpic,
  updateJobCodesQNQEpic,
  searchBusinessComponentEpic,
  searchLaborGroupingEpic,
  searchContractGroupingEpic,
  updateLaborGroupingEpic,
  overrideLaborGroupingEpic,
  searchSurveysEpic,
  getStudySummaryEpic,
  getNotificationEventsOverviewEpic,
  configureNotificationEpic,
  searchNotificationsEpic,
  configureSurveysEpic,
  searchContractPopulationEpic,
  searchSuppliesPopulationEpic,
  updateContractGroupingEpic,
  overrideContractGroupingEpic,
  updateProjectsQNQEpic,
  updateSurveyEpic,
  searchLaborQREsEpic,
  searchContractQREsEpic,
  searchSuppliesQREsEpic,
  updateQREsEpic,
  exportDataEpic,
  checkSurveysEpic,
  searchReportsEpic,
  triggerReportRefreshEpic,
  getReportRefreshStatusEpic,
  triggerImportEpic,
  fileImporterEpics,
  getNotificationFormDataEpic,
  deleteNotificationEpic,
  createNotificationScheduleEpic,
  getNotificationDetailsEpic,
  cancelScheduledNotificationEpic,
  getFileFromS3Epic,
  getImportMetadataEpic,
);
