import { AppLayout, Spinner } from '@amzn/awsui-components-react';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OPERATION, RESOURCE_TYPE } from '../../../app/auth/constants/rbac_constants';
import { getStudyPeriod } from '../../constants/study_period';
import StudyPeriodComponent from '../study_period/StudyPeriodComponent';
import BreadCrumb from './components/bread_crumb/BreadCrumb';
import { NOT_AUTHORIZED_TO_ACCESS_PAGE_BRC_ITEMS } from './components/bread_crumb/constants';
import NavBar from './components/nav_bar/NavBar';
import './css/index.css';
import { getNotificationFlash } from './notifications/utils';
import { clearPageNotification, listPermissionsAction } from './redux/reducer';

class Page extends StudyPeriodComponent {
  state = {
    navigationOpen: false,
  };

  componentDidMount = () => {
    const { permissions } = this.props;
    if (!permissions) {
      this.fetchData();
    }
  };

  fetchData() {
    const { listPermissionsBegin } = this.props;
    listPermissionsBegin();
  }

  // eslint-disable-next-line
  clearData() {}

  setNavigationOpen = navigationOpen => {
    this.setState({ navigationOpen });
  };

  isPageAccessible = () => {
    const { permissions, PageId, isPageAccessControlled, customAccessControl } = this.props;
    if (!isPageAccessControlled) {
      return true;
    }
    if (customAccessControl) return customAccessControl(permissions);

    return !!find(permissions, ({ operation, resourceName, resourceType }) => {
      return (
        operation === OPERATION.PAGE_CAN_ACCESS &&
        resourceType === RESOURCE_TYPE.PAGE &&
        resourceName === PageId
      );
    });
  };

  getPageContent = () => {
    const { PageContent } = this.props;
    return this.isPageAccessible() ? PageContent : null;
  };

  getBreadcrumbs = () => {
    const { permissions, breadCrumbItems } = this.props;
    const havePermissionsLoaded = permissions !== null;
    const bcItemsWhenNoPermissions = havePermissionsLoaded
      ? NOT_AUTHORIZED_TO_ACCESS_PAGE_BRC_ITEMS
      : null;
    return (
      <BreadCrumb items={this.isPageAccessible() ? breadCrumbItems : bcItemsWhenNoPermissions} />
    );
  };

  getNotifications = () => {
    const { notifications, dismissNotification } = this.props;
    return notifications.map(notification => {
      const { id } = notification;
      return getNotificationFlash(notification, () => dismissNotification({ id }));
    });
  };

  render() {
    const { loading } = this.props;
    const { navigationOpen } = this.state;
    return (
      <div className="page">
        <div className="position-relative">
          {loading && (
            <div className="spinner-div">
              <div className="spinner-translation-div">
                <Spinner size="large" />
              </div>
            </div>
          )}
          <div className="position-relative">
            <AppLayout
              content={
                <div className="awsui-grid awsui-util-p-s app-content-div">
                  {this.getPageContent()}
                </div>
              }
              breadcrumbs={this.getBreadcrumbs()}
              notifications={this.getNotifications()}
              navigation={<NavBar />}
              navigationWidth={250}
              navigationOpen={navigationOpen}
              onNavigationChange={({ detail: { open } }) => this.setNavigationOpen(open)}
              toolsHide
            />
          </div>
        </div>
      </div>
    );
  }
}

Page.propTypes = {
  PageId: PropTypes.string.isRequired,
  PageContent: PropTypes.element.isRequired,
  permissions: PropTypes.array,
  loading: PropTypes.bool,
  notifications: PropTypes.array,
  breadCrumbItems: PropTypes.array,
  isPageAccessControlled: PropTypes.bool,
  listPermissionsBegin: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  customAccessControl: PropTypes.func,
  studyPeriod: PropTypes.string.isRequired,
};

Page.defaultProps = {
  permissions: null,
  loading: false,
  notifications: null,
  breadCrumbItems: [],
  isPageAccessControlled: true,
};

const mapStateToProps = state => ({
  permissions: get(state, 'appMetaData.permissions.data', null),
  loading: get(state, 'appMetaData.ui.loading', false),
  notifications: get(state, 'appMetaData.ui.notification', null),
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType', undefined),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      listPermissionsBegin: listPermissionsAction.BEGIN,
      dismissNotification: clearPageNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Page);
