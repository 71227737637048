import { Button, Form, Header, SpaceBetween, Grid } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { get, isEmpty, isNull, isPlainObject } from 'lodash';
import { push } from 'connected-react-router';
import Page from '../../../common/components/with_page/Page';
import { RESOURCE } from '../../auth/constants/rbac_constants';
import { BRC_ITEMS_PAGE } from '../../../common/components/with_page/components/bread_crumb/constants';
import { getStudyPeriod } from '../../../common/constants/study_period';
import DetailComponent from './components/detail-section';
import AttachmentComponent from './components/attachment-section';
import TemplatePreviewComponent from './components/template-preview-section';
import DeliveryScheduleComponent from './components/delivery-schedule-section';
import {
  isAnyDaysOfWeekChecked,
  isEventSchedule,
  isOneTimeSchedule,
  isOnReferenceDateNotSelected,
  isRecurringSchedule,
  ONE_TIME_INITIAL_STATE,
  RECURRING_INITIAL_STATE,
  TRIGGERING_EVENT_INITIAL_STATE,
} from './constants/delivery-schedule-constant';
import { configureNotificationAction } from './redux/configure-notification-slice';
import createNotificationPayload from '../utils/api-utils';
import { getNotificationFormDataAction } from './redux/notification-form-data';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../common/constants/route_paths';
import { configureAttachmentStateAction } from './redux/configureAttachmentState';
import {
  getSelectedCCListOption,
  getSelectedCostCentersList,
  getSelectedDaysOfWeek,
  getSelectedObject,
  getUploadedAttachment,
} from '../utils/edit-notification-utils';

const ConfigureNotificationPage = ({
  studyPeriod,
  entityId,
  studyType,
  pageHeaderName,
  configureNotificationBegin,
  attachments,
  getNotificationFormData,
  navigateTo,
  history,
  notificationFormData,
  clearNotificationAttachments,
  addAttachments,
}) => {
  const notificationDetailsData = get(history, 'location.state', null) || null;
  useEffect(() => {
    getNotificationFormData();
    // eslint-disable-next-line
  }, []);
  const [detailComponentState, setDetailComponentState] = React.useState({
    name: '',
    template: { label: null, value: null },
    recipient: { label: null, value: null },
    selectedCCList: [],
    applicableTo: { label: null, value: null },
    applicableCCOperation: { label: null, value: null },
    selectedCostCenterIdentifier: [],
    selectedApplicableEntitySection: { label: null, value: null },
  });

  const [deliverySchedule, setDeliverySchedule] = React.useState({
    frequency: { label: null, value: null },
    trigger: { label: null, value: null },
    ...TRIGGERING_EVENT_INITIAL_STATE,
    ...ONE_TIME_INITIAL_STATE,
    ...RECURRING_INITIAL_STATE,
  });
  const [editNotificationId, setEditNotificationId] = React.useState(null);

  useEffect(() => {
    if (
      !isNull(notificationFormData) &&
      !isNull(notificationDetailsData) &&
      isPlainObject(notificationDetailsData)
    ) {
      setEditNotificationId(notificationDetailsData.notificationId);
      setDetailComponentState({
        name: notificationDetailsData.notificationName,
        template: getSelectedObject(
          notificationFormData.template,
          notificationDetailsData.templateId,
        ),
        recipient: getSelectedObject(
          notificationFormData.recipient,
          notificationDetailsData.recipient,
        ),
        selectedCCList: getSelectedCCListOption(
          notificationFormData.ccList,
          notificationDetailsData.ccList,
        ),
        applicableTo: getSelectedObject(
          notificationFormData.applicable,
          notificationDetailsData.applicableTo,
        ),
        applicableCCOperation: getSelectedObject(
          notificationFormData.applicableCostCenterOperation,
          notificationDetailsData.applicableCostCenter.operation,
        ),
        selectedCostCenterIdentifier: getSelectedCostCentersList(
          notificationDetailsData.applicableCostCenter.costCenterIdentifiers,
        ),
        selectedApplicableEntitySection: getSelectedObject(
          notificationFormData.applicableEntitySection,
          get(notificationDetailsData, 'applicableEntitySection.0', null),
        ),
      });
      const deliveryScheduleData = JSON.parse(notificationDetailsData.deliverySchedule);
      setDeliverySchedule({
        frequency: getSelectedObject(
          notificationFormData.frequency,
          deliveryScheduleData.frequency,
        ),
        trigger: getSelectedObject(notificationFormData.trigger, deliveryScheduleData.trigger),
        triggeringEvent: getSelectedObject(
          notificationFormData.triggeringEvent,
          deliveryScheduleData.schedule.event,
        ),
        relativeOperator: getSelectedObject(
          notificationFormData.relativeOperator,
          deliveryScheduleData.schedule.relativeOperator,
        ),
        relativeDate: getSelectedObject(
          notificationFormData.relativeDate,
          deliveryScheduleData.schedule.relativeDate,
        ),
        temporalUnit: getSelectedObject(
          notificationFormData.temporalUnit,
          deliveryScheduleData.schedule.temporalUnit,
        ),
        temporalData: deliveryScheduleData.schedule.temporalData,
        relativeStartDate: getSelectedObject(
          notificationFormData.relativeDate,
          deliveryScheduleData.schedule.relativeStartDate,
        ),
        relativeStartDateValue: deliveryScheduleData.schedule.relativeStartDateValue,
        relativeEndDate: getSelectedObject(
          notificationFormData.relativeDate,
          deliveryScheduleData.schedule.relativeEndDate,
        ),
        relativeEndDateValue: deliveryScheduleData.schedule.relativeEndDateValue,
        temporalDataList: deliveryScheduleData.schedule.temporalDataList,
        daysOfWeek: getSelectedDaysOfWeek(
          deliveryScheduleData.schedule.temporalDataList,
          deliverySchedule,
        ),
      });
      getUploadedAttachment(
        notificationDetailsData.attachments,
        clearNotificationAttachments,
        addAttachments,
      );
    }
    if (!isNull(notificationFormData) && isNull(notificationDetailsData)) {
      setDetailComponentState(prevDetails => ({
        ...prevDetails,
        selectedApplicableEntitySection: getSelectedObject(
          notificationFormData.applicableEntitySection,
          get(notificationFormData, 'applicableEntitySection.0.value', null),
        ),
      }));
    }
    // eslint-disable-next-line
  }, [notificationFormData, notificationDetailsData]);

  const onCreateHandler = () => {
    configureNotificationBegin({
      body: {
        notification: createNotificationPayload(
          editNotificationId,
          detailComponentState,
          attachments,
          deliverySchedule,
        ),
      },
      onSuccess: notificationId => {
        navigateTo({
          pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_DETAIL_PAGE, studyPeriod),
          state: notificationId,
        });
        clearNotificationAttachments();
      },
    });
  };

  const onCancelHandler = () => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_PAGE, studyPeriod),
    });
  };

  const isCreateButtonDisabled = () => {
    if (
      isEmpty(detailComponentState.name) ||
      isNull(detailComponentState.template.value) ||
      isNull(detailComponentState.recipient.value) ||
      isNull(detailComponentState.applicableTo.value) ||
      isNull(deliverySchedule.frequency.value)
    ) {
      return true;
    }
    if (isEventSchedule(deliverySchedule)) {
      return isNull(deliverySchedule.triggeringEvent.value);
    }
    if (isOnReferenceDateNotSelected(deliverySchedule)) {
      return isEmpty(deliverySchedule.temporalData) || isNull(deliverySchedule.temporalUnit.value);
    }
    if (isOneTimeSchedule(deliverySchedule)) {
      return (
        isEmpty(deliverySchedule.relativeDate.value) ||
        isNull(deliverySchedule.relativeOperator.value)
      );
    }
    if (!isRecurringSchedule(deliverySchedule)) {
      return isNull(deliverySchedule.trigger.value);
    }
    if (isRecurringSchedule(deliverySchedule)) {
      return (
        isNull(deliverySchedule.relativeStartDate.value) ||
        isNull(deliverySchedule.relativeEndDate.value) ||
        !isAnyDaysOfWeekChecked(deliverySchedule)
      );
    }
    return false;
  };

  const content = (
    <SpaceBetween size="l">
      <Header variant="h1">{pageHeaderName}</Header>
      <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }, { colspan: 2 }]}>
        <TemplatePreviewComponent selectedTemplate={detailComponentState.template} />
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onCreateHandler}
                disabled={isCreateButtonDisabled()}
              >
                {isNull(notificationDetailsData) ? 'Create' : 'Save'}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="xl">
            <DetailComponent
              detailComponentState={detailComponentState}
              setDetailComponentState={setDetailComponentState}
            />
            <AttachmentComponent />
            <DeliveryScheduleComponent
              deliverySchedule={deliverySchedule}
              setDeliverySchedule={setDeliverySchedule}
            />
          </SpaceBetween>
        </Form>
      </Grid>
    </SpaceBetween>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_NOTIFICATIONS}
      isPageAccessControlled={false}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.CREATE_NOTIFICATION(
        studyPeriod,
        entityId,
        studyType,
        pageHeaderName,
      )}
    />
  );
};

ConfigureNotificationPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
  pageHeaderName: PropTypes.string,
  configureNotificationBegin: PropTypes.func.isRequired,
  attachments: PropTypes.array.isRequired,
  getNotificationFormData: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  notificationFormData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  clearNotificationAttachments: PropTypes.func.isRequired,
  addAttachments: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const notificationDetailData = get(state, 'router.location.state', null);
  return {
    studyPeriod: getStudyPeriod(state),
    entityId: get(state, 'entityId.entityId', undefined),
    studyType: get(state, 'studyType.studyType'),
    attachments: get(state, 'entities.notifications.configureAttachmentState.attachments', []),
    notificationFormData: get(state, 'entities.notifications.notificationFormData.data', null),
    pageHeaderName: isNull(notificationDetailData) ? 'New notification' : 'Edit notification',
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      configureNotificationBegin: configureNotificationAction.BEGIN,
      getNotificationFormData: getNotificationFormDataAction.BEGIN,
      navigateTo: payload => push(payload),
      clearNotificationAttachments: configureAttachmentStateAction.CLEAR,
      addAttachments: configureAttachmentStateAction.ADD,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureNotificationPage);
