import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import BusinessComponentTable from './components/business_component_table/BusinessComponentTable';

const BusinessComponentsTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <BusinessComponentTable />
      </div>
    </Grid>
  );
};

export default BusinessComponentsTab;
