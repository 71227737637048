import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import SurveyTable from './components/SurveyTable';
import { getStudyPeriod } from '../../common/constants/study_period';

const SurveyPage = ({ studyPeriod, entityId, studyType }) => {
  const content = (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <SurveyTable />
      </div>
    </Grid>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_SURVEY}
      isPageAccessControlled
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.SURVEY(studyPeriod, entityId, studyType)}
    />
  );
};

SurveyPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
});

export default connect(mapStateToProps)(SurveyPage);
