import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Grid,
  Spinner,
  Alert,
  SpaceBetween,
  Button,
  Link,
} from '@amzn/awsui-components-react';
import { get, isEmpty, isNull } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFileFromS3Action } from '../../../../../../common/components/get_file_from_s3/redux/actions/GetFileFromS3';
import getPayloadForTemplateDownload from '../../../../utils/template-utils';

const NotificationContentContainer = ({ templateId, getFileFromS3, templateData }) => {
  const loadingView = (
    <div style={{ textAlign: 'center' }}>
      <Spinner size="big" />
    </div>
  );
  const [templateVisibleData, setTemplateVisibleData] = useState(loadingView);
  useEffect(() => {
    if (!isNull(templateId) && !isEmpty(templateId)) {
      getFileFromS3(getPayloadForTemplateDownload(templateId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);
  useEffect(() => {
    if (!isEmpty(templateData)) {
      setTemplateVisibleData(
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: templateData.body }}
        ></div>,
      );
    }
  }, [templateData]);
  return (
    <SpaceBetween size="m">
      <Alert
        visible
        dismissible={false}
        action={
          <Button>
            <Link
              // eslint-disable-next-line max-len
              href="https://issues.amazon.com/issues/create?assignedFolder=57e64fe0-a4f2-4f25-bf19-dc1dfea8f69e"
              target="_blank"
            >
              Cut SIM to FinTech
            </Link>
          </Button>
        }
      >
        To select a different template, click ‘Edit’. To edit template content, cut a SIM to
        Taskless.
      </Alert>

      <Container header={<Header>Preview</Header>}>
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }, { colspan: 3 }]}>
          <div></div>
          <div>{templateVisibleData}</div>
          <div></div>
        </Grid>
      </Container>
    </SpaceBetween>
  );
};

NotificationContentContainer.propTypes = {
  templateId: PropTypes.string.isRequired,
  templateData: PropTypes.object.isRequired,
  getFileFromS3: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  templateData: get(state, 'entities.notifications.configureTemplateState.template', {}),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFileFromS3: getFileFromS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContentContainer);
