import { Box, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { BRC_ITEMS_PAGE } from '../../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../../common/components/with_page/Page';
import { RESOURCE } from '../../auth/constants/rbac_constants';
import NotificationTypes from './components/NotificationTypes';
import { getStudyPeriod } from '../../../common/constants/study_period';

const NotificationEventsOverviewPage = ({ studyPeriod, entityId, studyType }) => {
  const content = (
    <SpaceBetween size="l">
      <Header variant="h1">Notification</Header>
      <Box>
        <NotificationTypes />
      </Box>
    </SpaceBetween>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_NOTIFICATIONS}
      isPageAccessControlled
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.NOTIFICATION(studyPeriod, entityId, studyType)}
    />
  );
};

NotificationEventsOverviewPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
});

export default connect(mapStateToProps)(NotificationEventsOverviewPage);
