import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Header, SpaceBetween } from '@amzn/awsui-components-react';
import Button from '@amzn/awsui-components-react/polaris/button';
import { push } from 'connected-react-router';
import Table from '../../../common/components/table/components/Table';
import surveyTableConfig, { checkSurveyRequest } from '../constants/survey_config';
import { searchSurveysAction } from '../redux/surveys';
import { checkSurveysAction } from '../redux/createSurveyChecker';
import { getStudyPeriod } from '../../../common/constants/study_period';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../common/constants/route_paths';
import { SUPPORTED_IMPORT_TYPES } from '../../import_data/constants/import_types';
import {
  getImportFailureNotification,
  getImportTypeMetadataForHeader,
  processUserLevelImportMetadata,
} from '../../import_data/utils/import_utils';
import { setPageNotification } from '../../../common/components/with_page/redux/reducer';
import { getImportMetadataAction } from '../../import_data/redux/GetImportMetadata';
import { SURVEY_TYPE } from '../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';

const SurveyTable = props => {
  const {
    checkSurveyPresent,
    isFetchingSurveys,
    totalSurveys,
    studyPeriod,
    surveyType,
    navigateTo,
  } = props;

  const [isNoSurveysAlertVisible, setNoSurveysAlertVisible] = useState(false);

  useEffect(() => {
    const enrichedCheckSurveyRequest = {
      ...checkSurveyRequest,
      body: {
        ...checkSurveyRequest.body,
        surveyType,
      },
    };
    checkSurveyPresent(enrichedCheckSurveyRequest);
    // eslint-disable-next-line
  }, [studyPeriod, surveyType]);

  useEffect(() => {
    setNoSurveysAlertVisible(totalSurveys === 0 && !isFetchingSurveys);
  }, [totalSurveys, isFetchingSurveys]);

  const createSurveyHandler = () => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.CREATE_SURVEY, studyPeriod),
    });
  };

  const { importMetadata, pushNotification } = props;
  let supportedImportTypes;
  if (SURVEY_TYPE.LABOR_DUAL === surveyType) {
    supportedImportTypes = SUPPORTED_IMPORT_TYPES.LABOR_DUAL_SURVEY;
  } else {
    supportedImportTypes = SUPPORTED_IMPORT_TYPES.SURVEY;
  }

  const [latestImportTypeMetadata, setLatestImportTypeMetadata] = React.useState(null);

  useEffect(() => {
    if (!isEmpty(importMetadata)) {
      const importTypeMetadata = getImportTypeMetadataForHeader(
        importMetadata,
        supportedImportTypes,
      );
      setLatestImportTypeMetadata(importTypeMetadata);

      const userLevelImportMetadata = processUserLevelImportMetadata(importMetadata);
      if (!isNull(userLevelImportMetadata)) {
        pushNotification(
          getImportFailureNotification({
            tableIdentifier: 'SurveyTable',
            ...userLevelImportMetadata,
            supportedImportTypes,
          }),
        );
      }
    }
  }, [importMetadata, supportedImportTypes, pushNotification]);

  return (
    <SpaceBetween size="l">
      <Header variant="h1">Surveys</Header>
      <Alert
        visible={isNoSurveysAlertVisible}
        dismissAriaLabel="Close alert"
        type="warning"
        action={
          <Button variant="primary" onClick={createSurveyHandler}>
            Create survey
          </Button>
        }
      >
        We don&apos;t have any survey. Create survey(s) for the SMEs.
      </Alert>
      <Table
        {...props}
        config={surveyTableConfig({ importTypeMetadata: latestImportTypeMetadata, surveyType })}
      />
    </SpaceBetween>
  );
};

SurveyTable.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  totalResultCount: PropTypes.number.isRequired,
  totalSurveys: PropTypes.number.isRequired,
  isFetchingSurveys: PropTypes.bool.isRequired,
  checkSurveyPresent: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  surveyType: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  importMetadata: PropTypes.array.isRequired,
  pushNotification: PropTypes.func.isRequired,
  getImportDataBegin: PropTypes.func.isRequired,
  getImportDataClear: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    schema: get(state, 'entities.schemas.data.pages.surveys.surveys', []) || [],
    data: get(state, 'entities.surveys.data', {}) || {},
    totalResultCount: get(state, 'entities.surveys.data.totalResultCount', 0) || 0,
    isFetchingData: get(state, 'entities.surveys.isFetching', false) || false,
    totalSurveys: get(state, 'entities.checkSurveys.data.totalResultCount', 0) || 0,
    isFetchingSurveys: get(state, 'entities.checkSurveys.isFetching', false) || false,
    studyPeriod: getStudyPeriod(state),
    surveyType: get(state, 'studyType.surveyType'),
    importMetadata: get(state, 'entities.getImportMetadata.data.body.importMetadata', []) || [],
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchSurveysAction.BEGIN,
      checkSurveyPresent: checkSurveysAction.BEGIN,
      clearData: searchSurveysAction.CLEAR,
      navigateTo: payload => push(payload),
      pushNotification: setPageNotification,
      getImportDataBegin: getImportMetadataAction.BEGIN,
      getImportDataClear: getImportMetadataAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTable);
