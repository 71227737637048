import { Box, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import GroupingContractTable from './components/contract_tab/components/contract_table/ContractTab';
import GroupingLaborTable from './components/labor_tab/components/LaborTab';
import PopulationSupplierTable from './components/supplies_tab/components/supplies_table/SuppliesTab';
import { getStudyPeriod } from '../../common/constants/study_period';

const GroupingPage = ({ studyPeriod, entityId, studyType }) => {
  const content = (
    <div>
      <Header variant="h1">Grouping</Header>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'Labor',
              id: 'first',
              content: <GroupingLaborTable />,
            },
            {
              label: 'Contract',
              id: 'second',
              content: <GroupingContractTable />,
            },
            {
              label: 'Supplies',
              id: 'third',
              content: <PopulationSupplierTable />,
            },
          ]}
        />
      </Box>
    </div>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_GROUPING}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.GROUPING(studyPeriod, entityId, studyType)}
    />
  );
};

GroupingPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    studyPeriod: getStudyPeriod(state),
    entityId: get(state, 'entityId.entityId', undefined),
    studyType: get(state, 'studyType.studyType'),
  };
};
export default connect(mapStateToProps)(GroupingPage);
