import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../common/components/table/constants';
import ProjectMappingActions from './ProjectMappingActions';

const EmptyTableView = () => <p>You don&apos;t have any Projects yet.</p>;

export default () => {
  return {
    dataTrackingId: 'projectId',
    dataLoadingText: 'Loading projects mapping',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Projects',
      Actions: ProjectMappingActions,
    },
    // TODO : This needs to be dynamic
    getDataRequestBody: () => {
      return {};
    },
  };
};
