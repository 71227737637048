import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudyPeriodComponent from '../../../../common/components/study_period/StudyPeriodComponent';
import { getStudyPeriod } from '../../../../common/constants/study_period';

import STUDY_SUMMARY_TYPES from '../../constants/studySummaries';
import { getStudySummaryActions } from '../../redux';
import OverallSurveyStatus from './components/overall_survey_status/OverallSurveyStatus';
import SurveyStatusDetails from './components/survey_status_details/SurveyStatusDetails';
import SurveyMetrics from './components/survey_metrics/SurveyMetrics';

class SurveysTab extends StudyPeriodComponent {
  componentDidMount() {
    const { surveysStudySummary } = this.props;
    if (!surveysStudySummary || isEmpty(surveysStudySummary)) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  fetchData() {
    const { getStudySummaryBegin, surveyType } = this.props;
    getStudySummaryBegin({
      body: {
        summaryType: STUDY_SUMMARY_TYPES.SURVEYS.key,
        surveyType,
      },
    });
  }

  clearData() {
    const { clearStudyStudySummary } = this.props;
    clearStudyStudySummary();
  }

  render() {
    const { surveysStudySummary, isSurveysStudySummaryLoading } = this.props;
    return (
      <SpaceBetween direction="vertical" size="l">
        <SurveyMetrics surveysStudySummary={surveysStudySummary} />
        <OverallSurveyStatus
          surveysStudySummary={surveysStudySummary}
          isSurveysStudySummaryLoading={isSurveysStudySummaryLoading}
        />
        <SurveyStatusDetails
          surveysStudySummary={surveysStudySummary}
          isSurveysStudySummaryLoading={isSurveysStudySummaryLoading}
        />
      </SpaceBetween>
    );
  }
}

const mapStateToProps = state => ({
  surveysStudySummary:
    get(state, `entities.studySummary.data.${STUDY_SUMMARY_TYPES.SURVEYS.key}`, {}) || {},
  isSurveysStudySummaryLoading: get(state, 'entities.studySummary.isFetching', false) || false,
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType', undefined),
  surveyType: get(state, 'studyType.surveyType'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStudySummaryBegin: getStudySummaryActions.BEGIN,
      clearStudyStudySummary: () => getStudySummaryActions.CLEAR(STUDY_SUMMARY_TYPES.SURVEYS.key),
    },
    dispatch,
  );

SurveysTab.propTypes = {
  surveysStudySummary: PropTypes.object,
  isSurveysStudySummaryLoading: PropTypes.bool,
  getStudySummaryBegin: PropTypes.func.isRequired,
  clearStudyStudySummary: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  surveyType: PropTypes.string,
};

SurveysTab.defaultProps = {
  surveysStudySummary: {},
  isSurveysStudySummaryLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysTab);
