import * as React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Button from '@amzn/awsui-components-react/polaris/button';
import { get } from 'lodash';
import { exportDataAction } from '../../../../../../../common/redux/export';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
  ITEM_TYPE_CONTRACT,
} from '../../../../../../../common/config/api_constants';
import FileImportModal from '../../../../../../../common/components/file_import_modal/FileImportModal';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../../../common/constants/study_period';
import { uploadFileToS3Action } from '../../../../../../../common/components/file_importer/redux/actions/UploadFileToS3';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  SUPPORTED_IMPORT_TYPES,
} from '../../../../../../import_data/constants/import_types';
import {
  getUploadToS3PayloadForTriggerImport,
  isImportDisabled,
  isImportInProgress,
} from '../../../../../../import_data/utils/import_utils';

const ContractVendorActions = ({
  exportDataBegin,
  query,
  studyPeriod,
  uploadFileToS3,
  config,
  entityId,
}) => {
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);
  // eslint-disable-next-line
  const showFileImportModal = () => {
    setFileImportModalVisibility(true);
  };

  const hideCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    // state will contain file details in props
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType: SUPPORTED_IMPORT_TYPES.POPULATION_CONTRACTS[0],
      }),
    );
  };
  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'POPULATION_CONTRACT_RESEARCH_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          regulatoryRegion: DEFAULT_COUNTRY,
          itemType: ITEM_TYPE_CONTRACT,
          clientId: DEFAULT_BUSINESS_ID + (entityId === undefined ? '' : entityId),
          query,
        },
      },
    });
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        onClick={showFileImportModal}
        disabled={
          isActionDisabledForStudyPeriod(studyPeriod) ||
          isImportInProgress(config) ||
          isImportDisabled(config)
        }
      >
        Import
      </Button>
      <Button onClick={onExportHandler}>Export</Button>
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={hideCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={hideCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};

ContractVendorActions.propTypes = {
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  entityId: PropTypes.string,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContractVendorActions);
