import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { get, size } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import UpdateProjectsModal from './UpdateProjectsModal';
import FileImportModal from '../../../../../../../common/components/file_import_modal/FileImportModal';
import { updateProjectsQNQAction } from '../redux/updateProjectsQnq';
import { uploadFileToS3Action } from '../../../../../../../common/components/file_importer/redux/actions/UploadFileToS3';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  SUPPORTED_IMPORT_TYPES,
} from '../../../../../../import_data/constants/import_types';

import { exportDataAction } from '../../../../../../../common/redux/export';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
} from '../../../../../../../common/config/api_constants';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../../../common/constants/study_period';
import {
  getImportDropdownItems,
  getUploadToS3PayloadForTriggerImport,
  isImportInProgress,
} from '../../../../../../import_data/utils/import_utils';

const ProjectActions = ({
  items,
  setSelectedItems,
  getDataBegin,
  exportDataBegin,
  query,
  studyPeriod,
  uploadFileToS3,
  config,
  entityId,
}) => {
  const [isEditModalVisible, setEditModalVisibility] = React.useState(false);
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);
  const [fileImportType, setFileImportType] = React.useState(null);

  // eslint-disable-next-line
  const showFileImportModal = ({ detail }) => {
    setFileImportType(detail.id);
    setFileImportModalVisibility(true);
  };

  const onCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType,
      }),
    );
  };

  const showEditModal = () => {
    setEditModalVisibility(true);
  };

  const onCancelEditModal = () => {
    setEditModalVisibility(false);
  };
  const getUserName = async () => {
    return get((await Auth.currentSession()).getIdToken(), 'payload.identities[0].userId', '');
  };
  const onConfirmEditModal = updates => {
    getUserName().then(userAlias =>
      getDataBegin({
        body: {
          projectName: get(updates, 'name', ''),
          isReconfirmed: true,
          projectDescription: get(updates, 'description', ''),
          projectId: get(items[0], 'projectId', ''),
          userAlias,
        },
      }),
    );
    setSelectedItems([]);
    setEditModalVisibility(false);
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'PROJECTS_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          country: DEFAULT_COUNTRY,
          businessId: DEFAULT_BUSINESS_ID + (entityId === undefined ? '' : entityId),
          query,
        },
      },
    });
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <ButtonDropdown
        items={getImportDropdownItems(SUPPORTED_IMPORT_TYPES.QNQ_PROJECTS, config)}
        onItemClick={showFileImportModal}
        disabled={isActionDisabledForStudyPeriod(studyPeriod) || isImportInProgress(config)}
      >
        Import
      </ButtonDropdown>
      <Button onClick={onExportHandler}>Export</Button>
      <Button
        disabled={size(items) !== 1 || isActionDisabledForStudyPeriod(studyPeriod)}
        onClick={showEditModal}
      >
        Update
      </Button>
      <UpdateProjectsModal
        items={items}
        isVisible={isEditModalVisible}
        onDismiss={onCancelEditModal}
        onConfirm={onConfirmEditModal}
        onCancel={onCancelEditModal}
      />
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={onCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={onCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};

ProjectActions.propTypes = {
  items: PropTypes.array.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  entityId: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    isFetchingData: get(state, 'entities.qnq.updateProjects.isFetching', false) || false,
    studyPeriod: getStudyPeriod(state),
    entityId: get(state, 'entityId.entityId'),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: updateProjectsQNQAction.BEGIN,
      clearData: updateProjectsQNQAction.CLEAR,
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActions);
