import { SURVEY_TYPE } from '../../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';
import { getTemplates } from '../utils/SurveyUtils';

/*
Survey rendering configuration based on different attributes.
This eliminates different type of conditional rendering on
different combination of attributes and keeps component clean,
configurable and extensible when we will have new types of jurisdiction
and survey type etc
 */
const surveyRenderingConfig = {
  Default: {
    CreateSurveyComponent: {
      showSurveyTemplate: true,
      setDefaultState: () => {},
    },
  },
  Radical: {
    [SURVEY_TYPE.LABOR_DUAL]: {
      CreateSurveyComponent: {
        showSurveyTemplate: false,
        setDefaultState: (state, setState, populationType, data) => {
          const selectedTemplate = getTemplates(data, SURVEY_TYPE.LABOR_DUAL)[0];
          setState({
            ...state,
            [populationType]: { ...state[populationType], template: selectedTemplate },
          });
        },
      },
    },
  },
};

// Traverse through the config using optional chaining, with fallback to defaults
const getSurveyComponentRenderingConfig = (clientId, surveyType, page) => {
  const config =
    surveyRenderingConfig?.[clientId]?.[surveyType]?.[page] ||
    surveyRenderingConfig?.Default?.[page]; // Global default config
  return config || {};
};

export default getSurveyComponentRenderingConfig;
