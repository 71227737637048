import { find } from 'lodash';

export const RESOURCE_TYPE = {
  PAGE: 'Page',
  FEATURE: 'Feature',
  DATA: 'Data',
};

export const RESOURCE = {
  PAGE_STUDY_SUMMARY: 'StudySummary',
  PAGE_QNQ: 'QNQ',
  PAGE_BUSINESS_COMPONENT: 'BusinessComponent', // This is mapped to resource in Bindle
  PAGE_POPULATION: 'Population',
  PAGE_GROUPING: 'Grouping',
  DATA_PAYROLL: 'Payroll',
  PAGE_SURVEY: 'Surveys',
  PAGE_NOTIFICATIONS: 'Notification',
  PAGE_QRE: 'QRE',
  PAGE_ANALYTICS: 'Analytics',
};

export const OPERATION = {
  FEATURE_CAN_OPERATE: 'can operate',
  PAGE_CAN_ACCESS: 'can access',
  DATA_CAN_READ: 'can read',
  DATA_CAN_WRITE: 'can write',
};

export const isPayrollDataAccessible = permissions => {
  return !!find(permissions, ({ operation, resourceName, resourceType }) => {
    return (
      operation === OPERATION.DATA_CAN_READ &&
      resourceType === RESOURCE_TYPE.DATA &&
      resourceName === RESOURCE.DATA_PAYROLL
    );
  });
};
