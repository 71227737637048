import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../../../common/components/table/components/Table';
import projectsMappingTableConfig from './project_mapping_table_config';
import { searchProjectsQNQAction } from '../../../../../qnq/components/projects_tab/components/projects_table/redux/projectsQnq';

/**
 *  This table is showing data from Project table index but in future if requirements evolve,
 *  it might pick data from different index, if that happes, we need to use BCItemType
 *  https://tiny.amazon.com/12i3whpgd/codeamazpackValablobca68mode
 *
 * See how this passed from Business Component -
 * https://tiny.amazon.com/dx186fcb/codeamazpackValablob57fcpack
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectsMappingTable = props => {
  return <Table {...props} config={projectsMappingTableConfig()} />;
};

ProjectsMappingTable.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    schema:
      get(state, 'entities.schemas.data.pages.businessComponent.businessComponentProjects', []) ||
      [],
    data: get(state, 'entities.qnq.projects.data', {}) || {},
    totalResultCount: get(state, 'entities.qnq.projects.data.totalResultCount', 0) || 0,
    isFetchingData: get(state, 'entities.qnq.projects.isFetching', false) || false,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchProjectsQNQAction.BEGIN,
      clearData: searchProjectsQNQAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsMappingTable);
