import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../common/components/table/constants';
import { getImportStatusHeaderDescription } from '../../../../../import_data/utils/import_utils';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../import_data/constants/import_types';
import { BC_SEARCH_TYPE_BUSINESS_COMPONENT } from '../../../../../../common/config/api_constants';
import BusinessComponentActions from './BusinessComponentActions';

const EmptyTableView = () => <p>No business components.</p>;

export default ({ importTypeMetadata }) => {
  return {
    dataTrackingId: 'partitionKey',
    dataLoadingText: 'Loading business components',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Business Components',
      description: getImportStatusHeaderDescription(importTypeMetadata),
      Actions: BusinessComponentActions,
      importTypeMetadata,
    },
    /*
      bcItemType must contain enum value instead of enum name from Service Model
      https://tiny.amazon.com/1bl49lygv/codeamazpackValablobca68mode
     */
    getDataRequestBody: () => {
      return {
        bcItemType: BC_SEARCH_TYPE_BUSINESS_COMPONENT,
      };
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes: SUPPORTED_IMPORT_TYPES.BUSINESS_COMPONENT,
        },
      };
    },
  };
};
