import {
  ColumnLayout,
  Container,
  Header,
  Spinner,
  ButtonDropdown,
} from '@amzn/awsui-components-react/polaris';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ValueWithLabel from '../../../../../../../common/components/value_with_label/ValueWithLabel';
import '../../../../../../../index.css';
import { requestType, exceptionsRequest } from '../constants/request_utils';
import { exportDataAction } from '../../../../../../../common/redux/export';
import { getStudyPeriod } from '../../../../../../../common/constants/study_period';

const EExceptionsCard = props => {
  const {
    isFetchingPopulationOverview,
    exceptionsCountDisplay,
    employeesWithoutHRData,
    employeesWithoutPayrollData,
    exportDataBegin,
    studyPeriod,
    entityId,
  } = props;

  const exportHandler = ({ detail }) => {
    exportDataBegin({
      body: {
        type: requestType[detail.id],
        ...exceptionsRequest(studyPeriod, entityId),
      },
    });
  };
  return (
    <Container
      className="full-height-card"
      header={
        <Header
          variant="h2"
          counter={exceptionsCountDisplay}
          actions={
            <ButtonDropdown
              items={[
                { text: 'HR', id: 'hr' },
                { text: 'Payroll', id: 'payroll' },
              ]}
              onItemClick={exportHandler}
            >
              Export
            </ButtonDropdown>
          }
        >
          Exceptions
        </Header>
      }
    >
      {isFetchingPopulationOverview ? (
        <Spinner />
      ) : (
        <ColumnLayout columns={2} variant="text-grid">
          <ValueWithLabel label="Employees without HR data">
            {employeesWithoutHRData}
          </ValueWithLabel>
          <ValueWithLabel label="Employees without payroll data">
            {employeesWithoutPayrollData}
          </ValueWithLabel>
        </ColumnLayout>
      )}
    </Container>
  );
};

EExceptionsCard.propTypes = {
  isFetchingPopulationOverview: PropTypes.bool.isRequired,
  exceptionsCountDisplay: PropTypes.string.isRequired,
  employeesWithoutHRData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeesWithoutPayrollData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exportDataBegin: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportDataBegin: exportDataAction.BEGIN,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(EExceptionsCard);
