import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import union from 'lodash/union';
import get from 'lodash/get';
import { isNumber } from 'lodash';
import SURVEY_STATUSES from '../../constants/surveyStatuses';
import { AGG_BY_SURVEY_TYPE_SORT_ARRAY, AGGREGATIONS_IDS } from '../../constants/aggregations';
import { getDateInJurisdictionSpecificTimeZone } from '../../../../../../common/utils/date_time_util';

export const getFormattedDate = isoDateString => {
  return getDateInJurisdictionSpecificTimeZone(isoDateString);
};

const getItemsForAggregationByDate = items => {
  return map(
    sortBy(items, ({ bucket }) => bucket),
    item => {
      const { bucket } = item;
      if (isNumber(Number(bucket))) {
        return {
          ...item,
          bucketLabel: getFormattedDate(bucket),
        };
      }

      return item;
    },
  );
};

export const getItems = ({ aggId, aggregation }) => {
  let items = map(aggregation, (item, bucket) => {
    return { ...item, bucket };
  });

  if (isEqual(aggId, AGGREGATIONS_IDS.BY_SURVEY_TYPE)) {
    items = sortBy(items, ({ bucket }) => {
      return indexOf(AGG_BY_SURVEY_TYPE_SORT_ARRAY, bucket);
    });
  } else if (isEqual(aggId, AGGREGATIONS_IDS.BY_DATE)) {
    items = getItemsForAggregationByDate(items);
  }

  return items;
};

export const getTableColumnDefinitions = ({ aggId, aggText }) => {
  return union(
    [
      {
        id: aggId,
        header: aggText,
        cell: e => get(e, ['bucketLabel']) || get(e, ['bucket']),
      },
    ],
    map(SURVEY_STATUSES, ({ label, key }) => {
      return {
        id: key,
        header: label,
        cell: e => get(e, [key], '-'),
      };
    }),
  );
};
