import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { map, reduce, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Calendar, DateInput, FormField } from '@amzn/awsui-components-react';
import {
  DATE_FIELD_OPERATORS,
  OPERATION_TRANSFORMATION_MAP,
  OPERATOR_TRANSFORMATION_MAP,
  OPERATORS,
  DATE_FIELDS_WITH_DATE_PICKER_ON_UI,
  TIMEZONE_CONVERSION_FOR_FILTERING_IN_UTC,
  EXCLUDE_DATE_FIELDS_FOR_FILTER,
} from '../constants';
import { changeDateToEpoch } from '../../../utils/date_time_util';

const I18N_STRINGS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',
  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',
  operationAndText: 'and',
  operationOrText: 'or',
  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',
  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',
  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

/**
 * This function is used to convert jurisdiction specific date to UTC date, which is required for
 * filtering correct date values present in Elastic Search already stored in UTC.
 */

const handleTimeZoneConversion = (date, propertyKey) => {
  let utcDate;
  if (propertyKey === 'endDate' || propertyKey === 'surveyDueDate') {
    utcDate = new Date(Number(changeDateToEpoch(`${date}`, 'endDate')));
  } else {
    utcDate = new Date(Number(changeDateToEpoch(`${date}`, 'releaseDate')));
  }

  // Extract year, month, and day in UTC
  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(utcDate.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getQueryString = queryObj => {
  const { tokens, operation } = queryObj;
  return trimStart(
    reduce(
      tokens,
      (queryString, { propertyKey, operator, value }) => {
        let modifiedValue = value;
        if (TIMEZONE_CONVERSION_FOR_FILTERING_IN_UTC.includes(propertyKey)) {
          modifiedValue = handleTimeZoneConversion(value, propertyKey);
        }

        return (
          `${queryString} ${OPERATION_TRANSFORMATION_MAP[operation]}` +
          ` ${propertyKey} ${OPERATOR_TRANSFORMATION_MAP[operator]} ${modifiedValue}`
        );
      },
      '',
    ),
    ` ${OPERATION_TRANSFORMATION_MAP[operation]} `,
  );
};

const getFilteringProperties = schema => {
  return map(schema || [], ({ key, displayName }) => {
    if (EXCLUDE_DATE_FIELDS_FOR_FILTER.includes(key)) {
      return null;
    }
    if (DATE_FIELDS_WITH_DATE_PICKER_ON_UI.includes(key)) {
      return {
        key,
        propertyLabel: displayName,
        groupValuesLabel: `${displayName} values`,
        operators: DATE_FIELD_OPERATORS.map(operator => ({
          operator,
          form: ({ value, onChange }) => {
            return (
              <div className="date-form">
                <FormField>
                  <DateInput
                    value={value ?? ''}
                    onChange={event => onChange(event.detail.value)}
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <Calendar
                  value={value ?? ''}
                  onChange={event => onChange(event.detail.value)}
                  locale="en-US"
                />
              </div>
            );
          },
          match: 'date',
        })),
      };
    }
    return {
      key,
      operators: OPERATORS,
      propertyLabel: displayName,
      groupValuesLabel: `${displayName} values`,
    };
  }).filter(Boolean);
};

const TOKEN_LIMIT = 5;
const IS_FREE_TEXT_FILTERING_DISABLED = true;

const TFilter = props => {
  const { schema, query, setQuery } = props;

  return (
    <PropertyFilter
      onChange={({ detail }) => setQuery(detail)}
      query={query}
      i18nStrings={I18N_STRINGS}
      filteringProperties={getFilteringProperties(schema)}
      tokenLimit={TOKEN_LIMIT}
      disableFreeTextFiltering={IS_FREE_TEXT_FILTERING_DISABLED}
    />
  );
};

TFilter.propTypes = {
  schema: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default TFilter;
