export const DEFAULT_TABLE_PAGE_SIZE = 20;

export const OPERATORS = ['=', '!=', ':', '!:'];

/**
 * contains and notContains value does not work for date related fields. Currently only equals
 * and notEquals operator are required. If customer requires <, <=, >, >= operators can be added
 * for better search capabilities to date fields.
 */
export const DATE_FIELD_OPERATORS = ['=', '!='];

export const OPERATOR_TRANSFORMATION_MAP = {
  '=': 'equals',
  '!=': 'notEquals',
  ':': 'contains',
  '!:': 'notContains',
};

export const OPERATION_TRANSFORMATION_MAP = {
  and: '&&',
  or: '||',
};

export const UPDATE_QNQ_DATA_REFRESH_DELAY_TIME_IN_MS = 10000;

export const UPDATE_QRE_DATA_REFRESH_DELAY_TIME_IN_MS = 5000;

export const UPDATE_SURVEY_DATA_REFRESH_DELAY_TIME_IN_MS = 6000;

export const UPDATE_GROUPING_DATA_REFRESH_DELAY_TIME_IN_MS = 15000;

/**
 * These date fields only have date value, and no timestamp present with them in DDB, so they
 * will not be converted to any timezone before displaying on UI
 *
 */
export const DATE_FIELDS_WITHOUT_TIMESTAMP = ['terminationDate', 'lastHireDate', 'monthEndDate'];

/**
 * These date fields have timestamp value after 2022, so before 2022 dates are not converted
 * into any specific timezone, and post 2022, dates will be converted to jurisdiction specific
 * timezone in MM-DD-YYYY format.
 */
export const DATETIME_FIELDS_WITH_TIMESTAMP_AFTER_2022_WITH_UI_MMDDYYYY = [
  'releaseDate',
  'surveyStartDate',
  'endDate',
  'surveyDueDate',
];

/**
 * These date fields have timestamp value, so dates will be converted to jurisdiction specific
 * timezone in MM-DD-YYYY format.
 *
 */
export const DATETIME_FIELDS_WITH_TIMESTAMP_WITH_UI_MMDDYYYY = [
  'createdOn',
  'surveySubmittedDate',
  'scheduleDate',
];

/**
 * These date fields have timestamp value which needs to be displayed on UI, so dates will be
 * converted to jurisdiction specific timezone in MM-DD-YYYY HH:MM:SS format.
 *
 */
export const DATETIME_FIELDS_WITH_TIMESTAMP_WITH_UI_MMDDYYYYHHMMSS = [
  'updatedDate',
  'hrDataAsOfDate',
  'payrollDataAsOfDate',
  'updatedOn',
];

/**
 * List of date fields which will show a Date Picker for filtering on UI.
 *
 */
export const DATE_FIELDS_WITH_DATE_PICKER_ON_UI = [
  'releaseDate',
  'endDate',
  'surveyDueDate',
  'surveyStartDate',
  'terminationDate',
  'lastHireDate',
  'monthEndDate',
  'scheduleDate',
];

/**
 * List of fields that require timezone conversion from jurisdiction specific
 * timezone to UTC timezone for filtering from ES
 */
export const TIMEZONE_CONVERSION_FOR_FILTERING_IN_UTC = [
  'releaseDate',
  'endDate',
  'scheduleDate',
  'surveyDueDate',
  'surveyStartDate',
];

// ToDo : Temporarily excluding these fields from filtering capabilities on UI because of issues
//  with timezone conversion.
export const EXCLUDE_DATE_FIELDS_FOR_FILTER = [
  'createdOn',
  'surveySubmittedDate',
  'updatedDate',
  'hrDataAsOfDate',
  'payrollDataAsOfDate',
  'updatedOn',
];
