import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GroupingPage from './app/grouping/GroupingPage';
import NotFoundPage from './app/not_found/NotFoundPage';
import PopulationPage from './app/population/PopulationPage';
import QNQPage from './app/qnq/QNQPage';
import StudySummaryPage from './app/study_summary/StudySummaryPage';
import {
  getRoutePathWithStudyPeriod,
  getRoutePathWithStudyPeriodPlaceholder,
  ROUTE_PATH,
} from './common/constants/route_paths';
import SurveyPage from './app/survey/SurveyPage';
import NotificationEventsOverviewPage from './app/notification/NotificationEventsOverview/NotificationEventsOverviewPage';
import NotificationsPage from './app/notification/Notifications/NotificationsPage';
import QREPage from './app/qre/QREPage';
import AnalyticsPage from './app/analytics/AnalyticsPage';
import { CURRENT_STUDY_PERIOD } from './common/constants/study_period';
import ConfigureNotificationPage from './app/notification/configure_notification/ConfigureNotificationPage';
import CreateSurveyPage from './app/survey/components/CreateSurveyPage';
import BusinessComponentPage from './app/business_component/BusinessComponentPage';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        path={ROUTE_PATH.HOME_PAGE}
        to={getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, CURRENT_STUDY_PERIOD)}
      />
      ;
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.STUDY_SUMMARY)}
        component={StudySummaryPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.POPULATION_PAGE)}
        component={PopulationPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.QNQ_PAGE)}
        component={QNQPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.BUSINESS_COMPONENT_PAGE)}
        component={BusinessComponentPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.GROUPING_PAGE)}
        component={GroupingPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.SURVEY_PAGE)}
        component={SurveyPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.CREATE_SURVEY)}
        component={CreateSurveyPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.NOTIFICATION_PAGE)}
        component={NotificationEventsOverviewPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.NOTIFICATION_DETAIL_PAGE)}
        component={NotificationsPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.CREATE_NOTIFICATION)}
        component={ConfigureNotificationPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.QRE_PAGE)}
        component={QREPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.ANALYTICS_PAGE)}
        component={AnalyticsPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.NOT_FOUND_PAGE)}
        component={NotFoundPage}
      />
      <Redirect to={getRoutePathWithStudyPeriod(ROUTE_PATH.NOT_FOUND_PAGE, CURRENT_STUDY_PERIOD)} />
    </Switch>
  );
};

export default Routes;
