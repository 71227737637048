import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../../../common/components/table/components/Table';
import { setPageNotification } from '../../../../../../common/components/with_page/redux/reducer';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../import_data/constants/import_types';
import {
  getImportFailureNotification,
  getImportTypeMetadataForHeader,
  processUserLevelImportMetadata,
} from '../../../../../import_data/utils/import_utils';
import businessComponentTableConfig from './business_component_table_config';
import { getImportMetadataAction } from '../../../../../import_data/redux/GetImportMetadata';
import { searchBusinessComponentActions } from './redux/businessComponentReducer';

const BusinessComponentTable = props => {
  const { importMetadata, pushNotification } = props;
  const supportedImportTypes = SUPPORTED_IMPORT_TYPES.BUSINESS_COMPONENT;
  const [latestImportTypeMetadata, setLatestImportTypeMetadata] = React.useState(null);

  useEffect(() => {
    if (!isEmpty(importMetadata)) {
      const importTypeMetadata = getImportTypeMetadataForHeader(
        importMetadata,
        supportedImportTypes,
      );
      setLatestImportTypeMetadata(importTypeMetadata);

      const userLevelImportMetadata = processUserLevelImportMetadata(importMetadata);
      if (!isNull(userLevelImportMetadata)) {
        pushNotification(
          getImportFailureNotification({
            tableIdentifier: 'BusinessComponentTable',
            ...userLevelImportMetadata,
            supportedImportTypes,
          }),
        );
      }
    }
  }, [importMetadata, supportedImportTypes, pushNotification]);

  return (
    <Table
      {...props}
      config={businessComponentTableConfig({
        importTypeMetadata: latestImportTypeMetadata,
      })}
    />
  );
};

BusinessComponentTable.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  importMetadata: PropTypes.array.isRequired,
  pushNotification: PropTypes.func.isRequired,
  getImportDataBegin: PropTypes.func.isRequired,
  getImportDataClear: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    schema:
      get(state, 'entities.schemas.data.pages.businessComponent.businessComponents', []) || [],
    data: get(state, 'entities.businessComponent.businessComponents.data', {}) || {},
    totalResultCount:
      get(state, 'entities.businessComponent.businessComponents.data.totalResultCount', 0) || 0,
    isFetchingData:
      get(state, 'entities.businessComponent.businessComponents.isFetching', false) || false,
    importMetadata: get(state, 'entities.getImportMetadata.data.body.importMetadata', []) || [],
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchBusinessComponentActions.BEGIN,
      clearData: searchBusinessComponentActions.CLEAR,
      pushNotification: setPageNotification,
      getImportDataBegin: getImportMetadataAction.BEGIN,
      getImportDataClear: getImportMetadataAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BusinessComponentTable);
