import { DEFAULT_STUDY_TYPE } from '../../../common/config/api_constants';
import { SURVEY_TYPE } from '../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';

export const STUDY_TYPE = {
  US_SINGLE_ALLOCATION: 'US_SINGLE_ALLOCATION',
  US_DUAL_ALLOCATION: 'US_DUAL_ALLOCATION',
  UK_DUAL_ALLOCATION: 'UK_DUAL_ALLOCATION', // This is defaulted in the UK
};

const getSurveyType = studyType => {
  return [STUDY_TYPE.US_SINGLE_ALLOCATION, STUDY_TYPE.UK_DUAL_ALLOCATION].includes(studyType)
    ? SURVEY_TYPE.LABOR
    : SURVEY_TYPE.LABOR_DUAL;
};

const initialState = {
  studyType: DEFAULT_STUDY_TYPE,
  surveyType: getSurveyType(DEFAULT_STUDY_TYPE),
};

const studyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STUDY_TYPE': {
      const { studyType } = action.payload;

      let surveyType;
      switch (studyType) {
        case STUDY_TYPE.US_SINGLE_ALLOCATION:
          surveyType = SURVEY_TYPE.LABOR;
          break;
        case STUDY_TYPE.US_DUAL_ALLOCATION:
          surveyType = SURVEY_TYPE.LABOR_DUAL;
          break;
        case STUDY_TYPE.UK_DUAL_ALLOCATION:
          surveyType = SURVEY_TYPE.LABOR;
          // TODO - Check impact of making it as LABOR_DUAL
          // because UK default survey are dual allocation
          // but UK is already deployed with labor only
          break;
        default:
          surveyType = undefined;
      }

      return { ...state, studyType, surveyType };
    }
    default:
      return state;
  }
};

export default studyTypeReducer;
