import { Box, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { isPayrollDataAccessible, RESOURCE } from '../auth/constants/rbac_constants';
import LaborTab from './components/labor_tab/components/LaborTab';
import ContractTab from './components/contract_tab/components/ContractTab';
import { getStudyPeriod } from '../../common/constants/study_period';
import SuppliesTab from './components/supplies_tab/components/SuppliesTab';

const QREPage = ({ studyPeriod, entityId, studyType }) => {
  const content = (
    <div>
      <Header variant="h1">QRE</Header>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'Labor',
              id: 'first',
              content: <LaborTab />,
            },
            {
              label: 'Contract',
              id: 'second',
              content: <ContractTab />,
            },
            {
              label: 'Supplies',
              id: 'third',
              content: <SuppliesTab />,
            },
          ]}
        />
      </Box>
    </div>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_QRE}
      isPageAccessControlled
      customAccessControl={isPayrollDataAccessible}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.QRE(studyPeriod, entityId, studyType)}
    />
  );
};

QREPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  entityId: PropTypes.string,
  studyType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  entityId: get(state, 'entityId.entityId', undefined),
  studyType: get(state, 'studyType.studyType'),
});

export default connect(mapStateToProps)(QREPage);
